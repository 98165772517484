import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  authenticationConfig: {
    clientID: 'dawHAgfFfCZJXSDv3753IJHWcEEoF28u',
    domain: 'frascheri-test.eu.auth0.com',
    redirectUri: 'https://professionale-pre.frascheri.com/',
    responseType: 'token id_token',
    scope: 'openid profile'
  },
  storageConfig: {
    commonAssetsUrl:
      'https://sitoprostorageaccount.blob.core.windows.net/common/',
    productsDataSheetsUrl: '/products-datasheets/',
    // productsDataSheetsUrl:
    //   'https://sitoprostorageaccount.blob.core.windows.net/products-datasheets/',
    productsImagesSmallUrl:
      'https://sitoprostorageaccount.blob.core.windows.net/products-images-small/',
    productsImagesMediumUrl:
      'https://sitoprostorageaccount.blob.core.windows.net/products-images-medium/',
    productsImagesLargeUrl:
      'https://sitoprostorageaccount.blob.core.windows.net/products-images-large/'
  },
  graphqlUri: 'professionale-api-pre.frascheri.com/v1alpha1/graphql',
  GA_ID: ''
};
